<template>
  <div class="change-password-dialog">
    <DialogVersion3 :visible.sync="visible" :visibleFooter="true">
      <div class="top">
        <p class="name">{{ $t('common.field.accNum') }}</p>
        <span class="number">{{number}}</span>
      </div>
      <div class="form-box">
        <el-form
          label-position="top"
          :model="changeFormLabelAlign"
          :rules="changingPasswordRules"
          ref="changeFormLabelAlign"
        >
          <el-form-item :label="$t('common.field.currPw')" prop="currentPassword" required class="currentPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.currentPassword"
              autocomplete="false"
              data-testid="currentPassword"
            ></el-input>
          </el-form-item>
          <div class="forgotPassword">
            <a class="forgotPwd" @click="forgotPassword" data-testid="forgotPw">{{ $t('common.button.forgotPw') }}</a>
          </div>
          <el-form-item type="password" :label="$t('common.field.newPw')" required prop="newPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.newPassword"
              autocomplete="false"
              data-testid="newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.field.confirmPw')" required prop="confirmNewPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.confirmNewPassword"
              autocomplete="false"
              data-testid="confirmNewPassword"
            ></el-input>
          </el-form-item>

          <div class="btn-box">
            <el-button
              class="btn-default"
              @click="changeSubmitForm"
              :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
              data-testid="changeSubmitForm"
            >
              {{ $t('common.button.changePw') }}
            </el-button>
            <el-button
              class="btn-blue"
              @click="disableChangePasswordVisible(false)"
              :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
              data-testid="disableChangePasswordVisible"
            >
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiUpdate_mt4_account_password, apiCheck_current_password, apiCheck_new_password } from '@/resource';
import { rsa } from '@/util/encrypt';

export default {
  props: {
    changePasswordVisible: Boolean,
    pwdConfirmVisible: Boolean,
    number: Number,
    isDemo: Boolean
  },
  components: { DialogVersion3 },
  data() {
    const validateCurrentPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        this.checkPasswordExisting().then(result => {
          if (!result) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            this.changeFormLabelAlign.currentPasswordVerification = true;
          }
        });
      }
    };
    const validateDuplicatedPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwAccFormat')));
        } else {
          this.checkPasswordDuplication().then(result => {
            if (!result) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')));
            } else {
              this.changeFormLabelAlign.newPasswordVerification = true;
              callback();
            }
          });
        }
      }
    };
    const validateConfirmNewPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.confirmNewPasswordVerfication = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.confirmPwReq')));
      } else if (value !== this.changeFormLabelAlign.newPassword) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      } else {
        callback();
        this.changeFormLabelAlign.confirmNewPasswordVerfication = true;
      }
    };
    return {
      visible: false,
      changeFormLabelAlign: {
        userId: '',
        mtAccount: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        currentPasswordVerification: false,
        newPasswordVerification: false,
        confirmNewPasswordVerfication: false
      },
      changingPasswordRules: {
        currentPassword: [{ validator: validateCurrentPassword, trigger: ['blur'] }],
        newPassword: [{ validator: validateDuplicatedPassword, trigger: ['blur', 'change'] }],
        confirmNewPassword: [{ validator: validateConfirmNewPassword, trigger: ['blur', 'change'] }]
      }
    };
  },

  watch: {
    changePasswordVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableChangePasswordVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    changeSubmitForm() {
      if (
        this.changeFormLabelAlign.currentPasswordVerification &&
        this.changeFormLabelAlign.newPasswordVerification &&
        this.changeFormLabelAlign.confirmNewPasswordVerfication
      ) {
        apiUpdate_mt4_account_password({
          userId: this.uuid,
          mtAccount: this.number,
          currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
          newPassword: rsa(this.changeFormLabelAlign.newPassword),
          isDemo: this.isDemo
        })
          .then(resp => {
            if (resp.data.code == 0) {
              this.$emit('update:changePasswordVisible', false);
              this.$emit('update:pwdConfirmVisible', true);
            }
          })
          .catch(err => {
            console.log(err);
            this.disableChangePasswordVisible(false);
          });
      }
    },
    disableChangePasswordVisible(bool) {
      this.$nextTick(() => {
        this.changeResetForm();
        this.$emit('update:changePasswordVisible', bool);
      });
    },
    changeResetForm() {
      this.$refs.changeFormLabelAlign.resetFields();
      this.$refs.changeFormLabelAlign.confirmNewPasswordVerfication = false;
      this.$refs.changeFormLabelAlign.currentPasswordVerification = false;
      this.$refs.changeFormLabelAlign.checkPasswordDuplication = false;
    },
    forgotPassword() {
      this.$emit('forgotPwdClick');
    },
    checkPasswordExisting() {
      return apiCheck_current_password({
        userId: this.uuid,
        mtAccount: this.number,
        currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('the password exists');
            return Promise.resolve(true);
          } else {
            console.log("the password doesn't exist");
          }
        } else {
          console.log('Errors happened when verifing current password');
        }
        return Promise.resolve(false);
      });
    },
    checkPasswordDuplication() {
      return apiCheck_new_password({
        userId: this.uuid,
        mtAccount: this.number,
        newPassword: rsa(this.changeFormLabelAlign.newPassword),
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log("The new password doesn't exist");
            return Promise.resolve(true);
          } else {
            console.log('The new password duplicates');
          }
        } else {
          //wrong
          console.log('Errors happend when verifying new password');
        }
        return Promise.resolve(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/changePassword.scss';
</style>
