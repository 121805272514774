<template>
  <div class="change-leverage-dialog">
    <DialogVersion3 :visible.sync="visible" :visibleFooter="true">
      <el-form
        :show-message="false"
        label-position="top"
        :rules="leverageUpdateFormRules"
        :model="leverageUpdateForm"
        ref="leverageUpdateForm"
      >
        <div class="top">
          <el-form-item :label="$t('common.field.accNum')">
            <span v-html="$parent.$parent.leveragePopupAccountNumber"></span>
          </el-form-item>
          <el-form-item :label="$t('common.field.currLeverage')" :class="{ 'long-text': ['es', 'pt'].includes(lang) }">
            <span>{{ $parent.$parent.leveragePopupLeverage }} : 1</span>
          </el-form-item>
          <el-form-item :label="$t('common.field.accEquity')">
            <span>{{ $parent.$parent.leveragePopupEquity | formatNumber }}</span>
          </el-form-item>
        </div>

        <el-form-item :label="$t('common.field.newLeverage')" prop="newLeverage">
          <el-select v-model="leverageUpdateForm.newLeverage" data-testid="newLeverage">
            <el-option
              name="newLeverage"
              v-for="item in $parent.$parent.leveragePopupAvaliableLeverages"
              :key="item.leverage"
              :value="item.leverage + ' : 1'"
              :data-testid="item.leverage"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- pro 1000/2000 maintion -->
        <div class="reminder-box" v-if="showProTnc && showProTncType">
          <div class="desc">{{ showProTncType.desc1 }}</div>
          <ul class="desc">
            <li v-for="(condition, index) in showProTncType.conditions" :key="index">{{ condition }}</li>
          </ul>
          <div class="desc">{{ showProTncType.desc2 }}</div>
          <div class="desc">{{ showProTncType.desc3 }}</div>
          <div class="desc">{{ showProTncType.desc4 }}</div>
        </div>

        <div v-if="showTNC" class="tnc">
          <el-form-item prop="tnc">
            <el-checkbox name="tnc" v-model="leverageUpdateForm.tnc" data-testid="tncConfirm"></el-checkbox>
            <i18n
              path="openAdditAcc.byTicking"
              tag="div"
              class="tnc-lable"
              :class="{ 'long-text': ['de'].includes(lang) }"
            >
              <template v-slot:tnc>
                <p class="red" @click="showTNCText = !showTNCText" data-testid="showTNCText">
                  {{ $t('openAdditAcc.tnc') }}
                </p>
              </template>
            </i18n>
          </el-form-item>
          <el-form-item class="info" v-show="showTNCText">
            <div v-html="$t('home.leverage.tnc')"></div>
          </el-form-item>
        </div>
        <div class="btn-box">
          <el-button
            class="btn-default"
            @click="submitForm"
            data-testid="confirmChangeLeverage"
            :class="{ 'long-text-btn': ['pt', 'es', 'de'].includes(lang) }"
          >
            {{ $t('common.button.changeLeverage') }}
          </el-button>
          <el-button class="btn-blue" @click="resetForm(false)" data-testid="cancelChangeLeverage">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </el-form>
    </DialogVersion3>

    <DialogVersion3 :visible.sync="checkVisible" :visibleFooter="true">
      <div class="dialog-body text-center">
        <p>{{ $t('home.leverage.reduceLeverageWarn') }}</p>
        <p>{{ $t('home.leverage.reduceLeverageConfirm') }}</p>
        <div class="btn-box">
          <el-button class="btn-default" @click="updateDemoLeverage" data-testid="updateDemoLeverage">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="btn-blue" @click="resetForm(false)" data-testid="cancelDialog">{{
            $t('common.button.cancel')
          }}</el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiUpdate_leverage, apiUpdate_leverage_demo } from '@/resource';
import { checkAgreedTNC } from '@/util/validation';

export default {
  props: {
    changeLeverageVisible: Boolean,
    leverageConfirmVisible: Boolean,
    leverageChangeSubmitSuccess: Boolean,
    leverageChangeSubmitFail: Boolean,
    leverageChangeSubmitReject: Boolean,
    leverageChangeAuditSuccess: Boolean,
    leverageChangeSubmitTimeOut: Boolean,
    showTNC: {
      type: Boolean,
      default: true
    },
    method: String
  },
  components: { DialogVersion3 },
  data() {
    const checkNewLeverage = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(''));
      }
      if (value.replace(' : 1', '') == this.$parent.$parent.leveragePopupLeverage) {
        return callback(new Error(''));
      }
      return callback();
    };
    return {
      visible: false,
      checkVisible: false,
      leverageUpdateForm: {
        metaTraderLogin: '',
        currentLeverage: '',
        newLeverage: '',
        tnc: false,
        mt4_datasource_id: ''
      },
      leverageUpdateFormRules: {
        tnc: [{ validator: checkAgreedTNC, trigger: 'change' }],
        newLeverage: [{ validator: checkNewLeverage, trigger: 'change' }]
      },
      showTNCText: false,
      showProTnc: false,
      showProTncType: null
    };
  },
  watch: {
    changeLeverageVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.resetForm(bool);
    },
    'leverageUpdateForm.newLeverage': {
      immediate: true,
      handler(newValue) {
        if (newValue === '1000 : 1' || newValue === '2000 : 1') {
          const level = newValue.replace(' : 1', '');
          this.showProTncType = {
            desc1: this.$t(`home.pro${level}.desc1`),
            conditions: this.$t(`home.pro${level}.conditions`),
            desc2: this.$t(`home.pro${level}.desc2`),
            desc3: this.$t(`home.pro${level}.desc3`),
            desc4: this.$t(`home.pro${level}.desc4`)
          };
          this.showProTnc = true;
        } else {
          this.showProTncType = '';
          this.showProTnc = false;
        }
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    submitForm() {
      this.$refs.leverageUpdateForm.validate(valid => {
        if (valid) {
          this.leverageUpdateForm.metaTraderLogin = this.$parent.$parent.leveragePopupAccountNumber;
          this.leverageUpdateForm.currentLeverage = this.$parent.$parent.leveragePopupLeverage;
          this.leverageUpdateForm.newLeverage = this.leverageUpdateForm.newLeverage.replace(' : 1', '');
          this.leverageUpdateForm.mt4_datasource_id = this.$parent.$parent.leverageMt4Datasource;
          if (this.method == 'live') {
            this.updateLeverage();
          } else if (this.method == 'demo') {
            if (this.leverageUpdateForm.newLeverage < this.leverageUpdateForm.currentLeverage) this.checkVisible = true;
            else this.updateDemoLeverage();
          }
        } else {
          return false;
        }
      });
    },
    updateLeverage() {
      apiUpdate_leverage(this.leverageUpdateForm)
        .then(resp => {
          this.successMethod(resp);
        })
        .catch(err => {
          this.errorMethod(err);
        })
        .finally(() => {
          this.resetForm(false);
          this.$emit('update:leverageConfirmVisible', true);
        });
    },
    updateDemoLeverage() {
      apiUpdate_leverage_demo({
        currentLeverage: this.leverageUpdateForm.currentLeverage,
        metaTraderLogin: this.leverageUpdateForm.metaTraderLogin,
        newLeverage: this.leverageUpdateForm.newLeverage,
        mt4_datasource_id: this.leverageUpdateForm.mt4_datasource_id
      })
        .then(resp => {
          this.successMethod(resp);
        })
        .catch(err => {
          this.errorMethod(err);
        })
        .finally(() => {
          this.resetForm(false);
          this.$emit('update:leverageConfirmVisible', true);
        });
    },
    successMethod(resp) {
      const returnCode = resp.data;
      if (returnCode == 0 || returnCode == 2) {
        this.$emit('update:leverageChangeSubmitSuccess', true);
        this.$emit('update:leverageChangeAuditSuccess', resp.data == 0);
      } else if (returnCode == 3) {
        this.$emit('update:leverageChangeSubmitReject', true);
      } else if (returnCode == 1) {
        this.$emit('update:leverageChangeSubmitFail', true);
      }
      this.$emit('resetTable');
    },
    errorMethod(err) {
      if (err.message.includes('timeout')) {
        this.$emit('update:leverageChangeSubmitTimeOut', true);
      } else {
        this.$emit('update:leverageChangeSubmitFail', true);
      }
    },
    resetForm(bool) {
      this.$nextTick(() => {
        this.checkVisible = false;
        this.$emit('update:changeLeverageVisible', bool);
        this.$refs.leverageUpdateForm.resetFields();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/changeLeverage.scss';
</style>
