<template>
  <div id="home">
    <div class="content-box">
      <div class="inner">
        <AuthenticationNote />
        <div class="link-parent-box">
          <slot name="alerts"></slot>
          <div class="link-box" v-if="showLink || id3Pass">
            <ul>
              <li class="active" @click="getDepositBlacklist()" data-testid="depositFunds">
                <img src="" class="img depositFunds" alt="" />
                <a> {{ $t('common.button.depositFunds') }}</a>
              </li>
              <li @click="getWithdrawalBlacklist()" data-testid="withdrawFunds">
                <img src="" class="img withdrawFunds" alt="" />
                <a> {{ $t('common.button.withdrawFunds') }}</a>
              </li>
              <li
                v-if="openAdditionAccountEnabled && $route.name != 'homeDemo'"
                @click="$router.push('openLiveAccount')"
                data-testid="openLiveAccount"
              >
                <img src="" class="img openLiveAccount" alt="" />
                <a> {{ $t('menu.openAdditAcc') }}</a>
              </li>
              <li
                v-if="$route.name == 'homeDemo'"
                @click="$router.push('openDemoAccount')"
                data-testid="openDemoAccount"
              >
                <img src="" class="img openDemoAccount" alt="" />
                <a> {{ $t('openDemoAdditAcc.button') }}</a>
              </li>
              <li v-if="checkIfIBUser()" @click.stop.prevent="$redirect.redirectToIbPortal" data-testid="ibPortal">
                <img src="" class="img downloads" alt="" />
                <a>{{ $t('common.button.ibPortal') }} </a>
              </li>
              <li v-else @click.stop.prevent="$router.push('downloads')" data-testid="download">
                <img src="" class="img downloads" alt="" />
                <a> {{ $t('common.button.download') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
    <slot name="components"></slot>
  </div>
</template>

<script>
import openAccountMixin from '@/mixins/openAccount';
import blackList from '@/mixins/blackList';
import AuthenticationNote from '@/components/security/AuthenticationNote';

export default {
  name: 'HomeTemplate',
  data() {
    return {};
  },
  components: {
    AuthenticationNote
  },
  mixins: [openAccountMixin, blackList],
  props: {
    showLink: { type: Boolean, default: true }
  },
  methods: {
    checkIfIBUser() {
      const userType = this.$store.state.common.userType;
      //ib type: individual 5, company 16
      if (userType === 5 || userType === 16) return true;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
