<template>
  <div class="password-dialog">
    <DialogVersion3 :visible.sync="visible" :visibleFooter="true">
      <div class="top">
        <p class="name">{{ $t('common.field.accNum') }}</p>
        <span class="number" v-html="number"></span>
      </div>
      <div class="btn-box">
        <el-button
          class="btn-blue"
          @click="changePwdClick"
          :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
          data-testid="changePw"
          >{{ $t('common.button.changePw') }}</el-button
        >
        <el-button
          class="btn-blue"
          @click="$emit('forgotPwdClick')"
          :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
          data-testid="forgetPw"
          >{{ $t('common.button.forgetPw') }}</el-button
        >
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiReq_reset_mt4_password } from '@/resource';

export default {
  props: {
    passwordVisible: Boolean,
    forgotPwdVisible: Boolean,
    number: Number,
    uuid: [Number, String]
  },
  components: { DialogVersion3 },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    passwordVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:passwordVisible', bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    changePwdClick() {
      this.$emit('update:passwordVisible', false);
      this.$parent.$parent.changePasswordVisible = true;
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/password.scss';
</style>
