import { apiQueryAvailableLeverages } from '@/resource'

export default {
  data() {
    return {
      changeLeverageVisible: false,
      leverageConfirmVisible: false,
      leveragePopupAccountNumber: null,
      leveragePopupLeverage: null,
      leveragePopupEquity: null,
      leveragePopupAvaliableLeverages: [],
      // leverage 狀態
      leverageChangeSubmitSuccess: false,
      leverageChangeSubmitFail: false,
      leverageChangeSubmitReject: false,
      leverageChangeAuditSuccess: false,
      leverageChangeSubmitTimeOut: false,
      leverageMt4Datasource: null,
    }
  },
  watch: {
    leverageConfirmVisible(bool) {
      if (!bool) {
        this.leverageChangeSubmitSuccess = false
        this.leverageChangeSubmitFail = false
        this.leverageChangeSubmitReject = false
        this.leverageChangeAuditSuccess = false
        this.leverageChangeSubmitTimeOut = false
      }
    },
  },
  methods: {
    leverageClick(index, row) {
      if (row.serverCategory == 6) return // copy trading cp not allow to change leverage
      this.changeLeverageVisible = true
      row.platform = 'AU'
      this.leveragePopupAccountNumber = row.mt4_account
      this.leveragePopupLeverage = row.leverage
      this.leveragePopupEquity = row.equity
      this.leverageMt4Datasource = row.mt4_datasource_id
      this.queryAvailableLeverages(row)
    },

    leverageDemoClick(row) {
      this.changeLeverageVisible = true
      this.leveragePopupAccountNumber = row.accNum
      this.leveragePopupLeverage = row.leverage
      this.leveragePopupEquity = row.equity
      this.leverageMt4Datasource = row.dataSourceId
      const data = {
        currency: row.currency,
        equity: row.equity,
        mt4_datasource_id: row.dataSourceId,
      }
      this.queryAvailableLeverages(data)
    },
    queryAvailableLeverages(account) {
      apiQueryAvailableLeverages(account).then(resp => {
        this.leveragePopupAvaliableLeverages = resp.data
      })
    },
  },
}
